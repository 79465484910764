import { Link } from 'gatsby';
import styled from 'styled-components';

import { breakpoint } from '@constants/theme';

export const CartItem = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  margin-top: 1.5rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
  overflow: hidden;
  gap: 11px;

  @media (min-width: ${breakpoint.md}px) {
    grid-template-columns: 160px 1fr;
    gap: 11px;
  }
`;

export const ItemImage = styled.img`
  width: 80px;
  height: 80px;
  display: block;
  border-radius: 15px;
  object-fit: contain;

  @media (min-width: ${breakpoint.md}px) {
    width: 160px;
    height: 160px;
  }
`;

export const ItemName = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  word-break: break-all;

  @media (min-width: ${breakpoint.md}px) {
    font-size: 18px;
  }
`;
