import { navigate } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import React, { FC } from 'react';
import styled from 'styled-components';

import { CartItem, ItemImage, ItemName } from '../CartItem';

import { CartShop } from '@api/cart-items/useCartItems';
import { useShopDetail } from '@api/shop/useShopDetail';
import { Button } from '@components/controls/button';
import { StyledSelect } from '@components/controls/select';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import { Paragraph } from '@components/typographies/paragraph';
import { breakpoint } from '@constants/theme';
import useCurrency from '@hooks/useCurrency';
import { handleClick, handleSelectChange } from '@lib/handlers';
import { useCartParams } from '@redux/slices/cartParamSlice';

const maxCount = 30;

type Props = {
  shop: CartShop;
};

export const CartShopComponent: FC<Props> = ({ shop }: Props) => {
  const intl = useIntl();
  const currency = useCurrency();
  const total = shop.amount;
  const items = shop.itemsList;
  const { updateItemCount, deleteCartParam } = useCartParams();
  const { shop: shopDetail } = useShopDetail(shop.shopId);

  return (
    <VStack spacing="0">
      {items.map((item, index) => (
        <CartItem key={index}>
          <ItemImage
            src={item.imageUrl}
            alt="商品画像"
            onClick={() => {
              navigate(`/items/${item.id}`);
            }}
          />
          <Block>
            <ItemName to={`/items/${item.id}`}>{item.name}</ItemName>
            <ShopName>{shopDetail?.name}</ShopName>
            <Flex jc="flex-end" flexWrap="wrap" gap="10px">
              <Paragraph align="right" fontWeight="700" fontSize="18px" width="max-content">
                ￥{item.price.toLocaleString()}
              </Paragraph>
              <StyledSelect
                width="60px"
                height="30px"
                value={String(item.quantity)}
                onChange={handleSelectChange((value) => {
                  updateItemCount({ itemId: item.id, itemCount: Number(value) });
                })}
              >
                {Array.from({ length: maxCount }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}個
                  </option>
                ))}
              </StyledSelect>
              <Button
                width="70px"
                height="30px"
                bg="gray"
                onClick={handleClick(() => {
                  deleteCartParam(item.id);
                })}
              >
                削除する
              </Button>
            </Flex>
          </Block>
        </CartItem>
      ))}
      <Summary>
        <FormattedMessage
          id="cart.message.5"
          values={{
            a: items.reduce((sum, item) => sum + item.quantity, 0).toLocaleString(),
            b: <strong>{intl.formatNumber(total, currency)}</strong>,
          }}
        />
      </Summary>
    </VStack>
  );
};

const ShopName = styled.p`
  font-size: 13px;
  color: var(--color-gray2);
`;

const Summary = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  padding-top: 50px;
  font-weight: 700;

  @media (min-width: ${breakpoint.md}px) {
    font-size: 21px;
  }
`;
