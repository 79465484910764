import styled from 'styled-components';

type Props = {
  width?: string;
  height?: string;
  padding?: string;
};

export const StyledSelect = styled.select<Props>`
  height: ${(_) => _.height};
  width: ${(_) => _.width};
  border: 1px solid var(--color-bg-gray);
  border-radius: 5px;
  padding: ${(_) => _.padding};
`;
