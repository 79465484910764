import React, { Suspense, FC } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { Layout } from '@components/layout';
import { description, title } from '@components/layout/SEO';
import { PageContainer } from '@components/layout/page-container';
import { Loading } from '@components/loading';
import { CartDetail } from '@features/cart';

const CartPage: FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>ショッピングカート | {title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Suspense fallback={<Loading />}>
        <CartPageContainer>
          <CartDetail />
        </CartPageContainer>
      </Suspense>
    </Layout>
  );
};

export default CartPage;

const CartPageContainer = styled(PageContainer)`
  padding: 0 10px;
`;
