import styled from 'styled-components';

import { breakpoint } from '@constants/theme';

export const PageContainer = styled.div`
  overflow-x: visible;
  padding 0 20px;

  @media (min-width: ${breakpoint.md}px) {
    max-width: 1097px;
    margin: 0 auto;
    padding: 30px;
  }
`;
