export { CartShopComponent } from './CartComponent';
export { DeleteItemModal } from './DeleteItemModal';

import { navigate } from 'gatsby';
import React, { FC, Fragment, Suspense } from 'react';
import styled from 'styled-components';

import { CartShopComponent } from './CartComponent';

import { useCartItems } from '@api/cart-items/useCartItems';
import { ReactComponent as CartIcon } from '@assets/image/svg/cart.svg';
import { Tabs } from '@components/common';
import { Button } from '@components/controls/button';
import { Flex } from '@components/layout/flexbox';
import { Anchor } from '@components/typographies/anchor';
import { Heading } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { breakpoint } from '@constants/theme';
import { useAppDispatch } from '@redux/hooks';
import { initPurchaseFlowState } from '@redux/slices/purchaseFlow';

export const CartDetail: FC = () => {
  const { cartShops, setOrderShopId } = useCartItems();
  const dispatch = useAppDispatch();

  return (
    <div>
      <CardHeading>
        <CartIcon width="32px" height="32px" stroke="orange" />
        ショッピングカート
      </CardHeading>
      <Suspense fallback={<>Loading...</>}>
        {cartShops.length === 0 && <Paragraph my="3rem">カートに何も入っていません。</Paragraph>}
        {cartShops.map((shop) => (
          <Fragment key={shop.shopId}>
            <Tabs
              tabs={[
                {
                  label: 'ショップカート',
                  Component: <CartShopComponent shop={shop} />,
                },
              ]}
            />
            <CartFooter>
              <PurchaseButton
                onClick={() => {
                  dispatch(initPurchaseFlowState());
                  setOrderShopId(shop.shopId);
                  navigate('/order/address/');
                }}
              >
                購入画面に進む
              </PurchaseButton>
            </CartFooter>
            <Flex jc="flex-end">
              <Anchor href="/" color="gray-800">
                ＞買い物を続ける
              </Anchor>
            </Flex>
          </Fragment>
        ))}
      </Suspense>
      {/* <Collapse title={intl.formatMessage({ id: 'notice.items' })}>
          <NoticeComponent />
        </Collapse> */}
    </div>
  );
};

const CardHeading = styled(Heading)`
  display: flex;
  align-items: flex-end;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  color: #f39800;
  padding: 0.5rem 0rem;
  margin-bottom: 0.5rem;

  > svg {
    margin-right: 0.2rem;
    transform: translateY(-0.2rem);
  }
`;

const PurchaseButton = styled(Button)`
  border-radius: 10px;
  margin-inline: auto;
  color: white;
  height: 48px;
  width: calc(100% - 8px);

  @media (min-width: ${breakpoint.md}px) {
    width: 280px;
    margin: 0;
  }
`;

const CartFooter = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;
